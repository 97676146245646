import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import InlineComponent from '~/components/InlineComponent';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { ImportStatement } from '~/components/ImportStatement';
import { PrimaryButton, SecondaryButton } from '@entur/button';
import { BannerAlertBox, ToastAlertBox, ToastProvider, SmallAlertBox, useToast, SmallExpandableAlertBox } from '@entur/alert';
import { banner } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<BannerAlertBox variant=\"information\"></BannerAlertBox>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PageHeader,
      InlineComponent,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      PrimaryButton,
      SecondaryButton,
      BannerAlertBox,
      ToastAlertBox,
      ToastProvider,
      SmallAlertBox,
      useToast,
      SmallExpandableAlertBox,
      banner,
      DefaultLayout,
      _frontmatter
    }} props={banner} mdxType="Playground">
  <BannerAlertBox variant="information" mdxType="BannerAlertBox"></BannerAlertBox>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "banneralertbox"
    }}>{`BannerAlertBox`}</h3>
    <ImportStatement imports="BannerAlertBox" mdxType="ImportStatement" />
    <Props of={BannerAlertBox} mdxType="Props" />
    <h3 {...{
      "id": "toastalertbox"
    }}>{`ToastAlertBox`}</h3>
    <ImportStatement imports="ToastAlertBox" mdxType="ImportStatement" />
    <Props of={ToastAlertBox} mdxType="Props" />
    <h3 {...{
      "id": "toastprovider"
    }}>{`ToastProvider`}</h3>
    <ImportStatement imports="useToast" mdxType="ImportStatement" />
    <ImportStatement imports="ToastProvider" mdxType="ImportStatement" />
    <Props of={ToastProvider} mdxType="Props" />
    <br />
    <Playground __position={5} __code={'<div\n  style={{\n    display: \'grid\',\n    gridTemplateColumns: \'1fr 1fr\',\n    gap: \'1em 1em\',\n    alignItems: \'center\',\n    justifyItems: \'center\',\n  }}\n>\n  <ToastAlertBox variant=\"success\" title=\"Nå har du billett!\">\n    Ha det gøy på togturen til Bergen!\n  </ToastAlertBox>\n  <ToastAlertBox variant=\"information\" title=\"Toget går fra plattform 2\">\n    Husk å sjekke plattform når du ankommer.\n  </ToastAlertBox>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PageHeader,
      InlineComponent,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      PrimaryButton,
      SecondaryButton,
      BannerAlertBox,
      ToastAlertBox,
      ToastProvider,
      SmallAlertBox,
      useToast,
      SmallExpandableAlertBox,
      banner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1em 1em',
        alignItems: 'center',
        justifyItems: 'center'
      }}>
    <ToastAlertBox variant="success" title="Nå har du billett!" mdxType="ToastAlertBox">
      Ha det gøy på togturen til Bergen!
    </ToastAlertBox>
    <ToastAlertBox variant="information" title="Toget går fra plattform 2" mdxType="ToastAlertBox">
      Husk å sjekke plattform når du ankommer.
    </ToastAlertBox>
  </div>
    </Playground>
    <InlineComponent mdxType="InlineComponent">
  {() => {
        const {
          addToast
        } = useToast();
        return <React.Fragment>
        <PrimaryButton onClick={() => addToast({
            title: 'Dette er din første toast!',
            content: 'Den forsvinner om ca 6 sekunder'
          })} type="button" style={{
            marginRight: '1rem'
          }} mdxType="PrimaryButton">
          Lag suksess-toast
        </PrimaryButton>
        <SecondaryButton onClick={() => addToast({
            title: 'Toasts burde brukes sparsomt!',
            content: 'For mye av det gode er aldri bra.',
            variant: 'information'
          })} type="button" mdxType="SecondaryButton">
          Lag information-toast
        </SecondaryButton>
      </React.Fragment>;
      }}
    </InlineComponent>
    <h4 {...{
      "id": "hvordan-bruke-toasts"
    }}>{`Hvordan bruke toasts`}</h4>
    <p>{`Du får fiks ferdige toast-funksjonalitet på null komma niks med `}<inlineCode parentName="p">{`@entur/alert`}</inlineCode>{`. Først må du wrappe appen din i en `}<inlineCode parentName="p">{`<ToastProvider />`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ToastProvider } from '@entur/alert';

const App = () => {
  return (
    <ToastProvider>
      <Router>{/* Osv */}</Router>
    </ToastProvider>
  );
};
`}</code></pre>
    <p>{`For å lage en toast, så må du bruke hooken `}<inlineCode parentName="p">{`useToast`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useToast } from '@entur/alert';

const SaveButton = ({ children }) => {
  const { addToast } = useToast();
  return <button onClick={() => addToast('Lagret!')}>
}
`}</code></pre>
    <p>{`Du kan enten sende inn en string, eller et objekt med følgende form:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  title: 'Tittelen på toasten',
  content: <>Mer <em>utfyllende</em> informasjon</>
  variant: 'information' // eller 'success', som er default
}
`}</code></pre>
    <p>{`Toasts vil lukkes automatisk etter 6 sekunder, med mindre du flytter musepekeren over dem, eller lukker dem manuelt. Du kan sette den automatiske lukketiden for toasts i din app ved å sette `}<inlineCode parentName="p">{`delay`}</inlineCode>{` propen på `}<inlineCode parentName="p">{`<ToastProvider />`}</inlineCode>{` (antall millisekunder):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<ToastProvider delay={10000} />
`}</code></pre>
    <h3 {...{
      "id": "smallalertbox"
    }}>{`SmallAlertBox`}</h3>
    <ImportStatement imports="SmallAlertBox" mdxType="ImportStatement" />
    <Props of={SmallAlertBox} mdxType="Props" />
    <p>{`Små alert-bokser brukes når de skal plasseres inni en annen UI-komponent.`}</p>
    <Playground __position={8} __code={'<div\n  style={{\n    display: \'grid\',\n    gridTemplateColumns: \'1fr 1fr\',\n    gap: \'1em 1em\',\n  }}\n>\n  <SmallAlertBox variant=\"success\">Kjøpet gikk i orden!</SmallAlertBox>\n  <SmallAlertBox variant=\"information\">\n    Toget går fra plattform 2\n  </SmallAlertBox>\n  <SmallAlertBox variant=\"warning\" width=\"fit-content\">\n    Snøvær på Bergensbanen\n  </SmallAlertBox>\n  <SmallAlertBox variant=\"negative\" width=\"fit-content\">\n    Kjøpet kunne ikke gjennomføres!\n  </SmallAlertBox>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PageHeader,
      InlineComponent,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      PrimaryButton,
      SecondaryButton,
      BannerAlertBox,
      ToastAlertBox,
      ToastProvider,
      SmallAlertBox,
      useToast,
      SmallExpandableAlertBox,
      banner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1em 1em'
      }}>
    <SmallAlertBox variant="success" mdxType="SmallAlertBox">Kjøpet gikk i orden!</SmallAlertBox>
    <SmallAlertBox variant="information" mdxType="SmallAlertBox">Toget går fra plattform 2</SmallAlertBox>
    <SmallAlertBox variant="warning" width="fit-content" mdxType="SmallAlertBox">
      Snøvær på Bergensbanen
    </SmallAlertBox>
    <SmallAlertBox variant="negative" width="fit-content" mdxType="SmallAlertBox">
      Kjøpet kunne ikke gjennomføres!
    </SmallAlertBox>
  </div>
    </Playground>
    <p>{`Default så opptar disse varslene full bredde. Om ønskelig kan du sette `}<inlineCode parentName="p">{`width="fit-content"`}</inlineCode>{` for å la dem ta størrelsen til innholdet.`}</p>
    <h3 {...{
      "id": "expandablealertbox"
    }}>{`ExpandableAlertBox`}</h3>
    <ImportStatement imports="SmallExpandableAlertBox, BannerExpandableAlertBox" mdxType="ImportStatement" />
    <Props of={SmallExpandableAlertBox} mdxType="Props" />
    <p>{`Av og til vil du vise meldinger som gjerne inneholder mer informasjon, som du ikke alltid har behov for å vise.
Da kan man bruke `}<inlineCode parentName="p">{`SmallExpandableAlertBox`}</inlineCode>{` eller `}<inlineCode parentName="p">{`BannerExpandableAlertBox`}</inlineCode>{` for å vise en tittel, og ekstra informasjon skjult bak en ekspanderbart seksjon.`}</p>
    <Playground __position={10} __code={'<SmallExpandableAlertBox\n  variant=\"negative\"\n  title=\"Kjøpet kunne ikke gjennomføres!\"\n>\n  Vi har et teknisk problem akkurat nå. Kjøpsløsningen for billetter fungerer\n  ikke.\n</SmallExpandableAlertBox>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PageHeader,
      InlineComponent,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      PrimaryButton,
      SecondaryButton,
      BannerAlertBox,
      ToastAlertBox,
      ToastProvider,
      SmallAlertBox,
      useToast,
      SmallExpandableAlertBox,
      banner,
      DefaultLayout,
      _frontmatter
    }} style={{
      width: '62%',
      margin: '0 auto'
    }} mdxType="Playground">
  <SmallExpandableAlertBox variant="negative" title="Kjøpet kunne ikke gjennomføres!" mdxType="SmallExpandableAlertBox">
    Vi har et teknisk problem akkurat nå. Kjøpsløsningen for billetter fungerer
    ikke.
  </SmallExpandableAlertBox>
    </Playground>
    <h2 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h2>
    <p>{`Komponenten skal brukes som en valideringsmelding som varsler brukeren om at noe de nettopp
gjorde må korrigeres eller som en bekreftelse på at en handling ble fullført.
Varsler brukes også som en utrop eller varsling for viktig informasjon som brukeren bør ta stilling til.
Skriv en informativ tekst som gir brukeren en utfyllende beskrivelse av situasjonen, dens konsekvenser og måter å komme seg ut av.`}</p>
    <h3 {...{
      "id": "varianter"
    }}>{`Varianter`}</h3>
    <h4 {...{
      "id": "information"
    }}>{`Information:`}</h4>
    <p>{`Informasjonsvarslingen er blå. Den gir brukeren relevante tips eller informasjon som man behøver å vite når man skal utføre en oppgave.`}</p>
    <h4 {...{
      "id": "success"
    }}>{`Success:`}</h4>
    <p>{`Suksessvarselet er grønn og vises når en handling er vellykket. For eksempel i en kjøpsflyt der betalingen ble gjennomført.`}</p>
    <h4 {...{
      "id": "warning"
    }}>{`Warning:`}</h4>
    <p>{`Advarselen er gul og brukes for å advare brukeren om viktig informasjon som trenger oppmerksomhet. For eksempel avvik i trafikken eller at man skal benytte bakdører til av- og påstigning.`}</p>
    <h4 {...{
      "id": "negative"
    }}>{`Negative:`}</h4>
    <p>{`Feilvarslingen er rød. Den indikerer at noe gikk galt etter å ha utført en handling, eller for å varsle brukeren om en feil som trenger øyeblikkelig oppmerksomhet. For eksempel skjemaet kunne ikke lagres fordi det mangler data på noen felter.`}</p>
    <h3 {...{
      "id": "banneralertbox-1"
    }}>{`BannerAlertBox`}</h3>
    <p><inlineCode parentName="p">{`BannerAlertBox`}</inlineCode>{` har to spesifikke bruksområder: `}<em parentName="p">{`globale varsler`}</em>{` og `}<em parentName="p">{`lokale varsler`}</em>{`.`}</p>
    <h4 {...{
      "id": "globale-varsler"
    }}>{`Globale varsler`}</h4>
    <p>{`Globale varsler brukes når man ønsker å meddele informasjon på tvers av en applikasjon.
Eksempler kan være cookie-info, et API som er nede eller generalstreik som påvirker alt og alle.`}</p>
    <p>{`Globale varsler finnes i information, warning og negative-utførelse (ikke suksess).
De skal dekke hele toppen av siden, og skyver ned sidens innhold.
De har vanligvis muligheten til å bli lukket, slik at brukerne kan fjerne den.
Beskjeden bør dukke opp igjen neste gang man laster siden, men dette er opp til konsumenten.`}</p>
    <h4 {...{
      "id": "lokale-varsler"
    }}>{`Lokale varsler`}</h4>
    <p>{`Lokale varsler brukes for å vise informasjon om en underside, en seksjon eller et skjema.
Oppsummering av valideringsfeil, advarsel om manglende tariffkoder og generell avgangsinformasjon kan være gode brukseksempler.`}</p>
    <p>{`Lokale varsler bør inneholde kort og konsis informasjon, men kan også inneholde mer detaljert informasjon for "proffe" brukere.
Slik ekstra informasjon bør skjules bak en ekspanderbar lenke. Disse varslene bør vanligvis ikke være lukkbare, siden de ikke okkuperer like mye plass som de globale.`}</p>
    <p>{`Dette er den typen varsel vi bruker oftest i løsningene våre, men de bør fortsatt brukes sparsomt.
Siden de er ganske sterke visuelle virkemidler, bør hver og en få stå for seg selv og få den oppmerksomheten den fortjener.`}</p>
    <h3 {...{
      "id": "toasts"
    }}>{`Toasts`}</h3>
    <p>{`Toasts brukes i respons på visse typer handlinger og hendelser, og vises automatisk.
De bør forsvinne etter minst 6 sekunder, eller om man trykker på lukke-knappen.
Lukke-knappen bør dukke opp når man holder musepekeren over feltet, og "nedtellingen" for å automatisk fjerne knappen bør starte på nytt.`}</p>
    <p>{`Toasts er kun tilgjengelige i typene success og information, men vi bruker for det meste success-varselet.
De skal kun brukes som reaksjon på en hendelse, eller vise tidssensitiv informasjon.
Et godt eksempel kan være at brukeren er oppdatert, eller at en annen bruker har begynt å redigere samme dokument.`}</p>
    <p>{`Toast-varsler skal ikke plasseres over kritisk funksjonalitet, som knapper, navigasjon og andre funksjoner.
De dukker opp nedenfra på små flater, og ligger øverst i høyre hjørne på store flater.`}</p>
    <h4 {...{
      "id": "toasts-og-universell-utforming"
    }}>{`Toasts og universell utforming`}</h4>
    <p>{`Toast-varsler kan være utfordrende med tanke på universell utforming. Derfor har vi valgt å ikke tilby variantene error og warning.
Feilmeldinger og advarsler bør presenteres permanent og i kontekst, nær det aktuelle problemet, med tydelige forslag til rettelser.`}</p>
    <p>{`WCAG-punkter som er relatert til bruk av toasts:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uutilsynet.no/wcag-standarden/333-forslag-ved-feil-niva-aa/118"
        }}>{`Retningslinje 3.3.3 – Forslag ved feil`}</a></li>
    </ul>
    <p>{`Brukeren skal få tydelige instruksjoner eller forslag når en feil oppstår, noe som kan være vanskelig å oppnå i en toast.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uutilsynet.no/wcag-standarden/22-nok-tid/720"
        }}>{`Prinsipp 2.2 - Nok tid`}</a></li>
    </ul>
    <p>{`Gi brukerne nok tid til å lese og bruke innhold.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uutilsynet.no/wcag-standarden/131-informasjon-og-relasjoner-niva/90"
        }}>{`Retningslinje 1.3.1 – Informasjon og relasjoner`}</a></li>
    </ul>
    <p>{`Informasjon skal ha logiske relasjoner. Om toasten ikke er implementert riktig og feilmeldingen bare visuelt er koblet til relatert informasjon vil ikke alle brukere forstå sammenhengen med informasjonen.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uutilsynet.no/wcag-standarden/1413-pekerfolsomt-innhold-eller-innhold-ved-tastaturfokus-niva-aa/147"
        }}>{`Retningslinje 1.4.13 - Pekerfølsomt innhold eller innhold ved tastaturfokus`}</a>{` `}</li>
    </ul>
    <p>{`Viktig innhold som vises midlertidig må være tilgjengelig via tastatur, og forbli synlig til brukeren aktivt lukker det, eller så lenge det er nødvendig for å forstå meldingen.`}</p>
    <h3 {...{
      "id": "smallalertbox-1"
    }}>{`SmallAlertBox`}</h3>
    <p><inlineCode parentName="p">{`SmallAlertBox`}</inlineCode>{` brukes som `}<em parentName="p">{`kontekstuelle varsler`}</em>{` i grensesnittet.`}</p>
    <p>{`Kontekstuelle varsler finnes i alle typer og vises i direkte tilknytning til innholdet det omtaler.
De brukes ofte for å supplere innhold med ekstra informasjon.`}</p>
    <p>{`Kontekstuelle varsler bør ikke kunne lukkes, og bør være ekstra korte og konsise.
Disse brukes ikke så ofte, og man kan ofte benytte valideringsmeldinger istedenfor.`}</p>
    <h2 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h2>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="God praksis" variant="success" textInBox mdxType="DoDontCard">
    <ul>
      <li>Bruk varsler sparsomt</li>
      <li>Hold det kort og konsist</li>
      <li>
        Hvis feilen er knyttet til et spesifikk tekstfelt, bør man også gi disse
        feltene en feiltilstand
      </li>
      <li>
        Hvis det kreves at brukeren må gjøre noe som svar på et varsel, må man
        gi beskjed om hva de trenger å gjøre for å gjennomføre oppgaven.
      </li>
      <li>Tillatt brukeren å avvise et varsel der det er aktuelt.</li>
      <li>Bruk standardikonet for information, suksess, advarsel og feil.</li>
    </ul>
  </DoDontCard>
  <DoDontCard title="Unngå" variant="warning" textInBox mdxType="DoDontCard">
    <ul>
      <li>Ikke legg varsler over innhold på siden</li>
      <li>
        Ikke overdriv bruken av varsler. For mange vil enten overvelde eller
        irritere brukeren
      </li>
      <li>
        Ikke legg skylden på brukeren ved en feilmelding. Ha en vennlig tone og
        hold språket forståelig
      </li>
      <li>
        Ikke ta med varsler som ikke er relevant for brukerens nåværende oppgave
      </li>
    </ul>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      